import React, { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from "dompurify";

const ProgramCatering = () => {
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [bannerData, setBannerData] = useState(null);
  const bannerId = 2;

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(
          "https://api.dermalicious.id/api/program-catering"
        );
        if (Array.isArray(response.data)) {
          setPrograms(response.data);
        } else {
          setError("Unexpected response format");
        }
      } catch (err) {
        setError("Failed to fetch programs");
      }
    };

    const fetchBannerData = async () => {
      try {
        const response = await axios.get(
          "https://api.dermalicious.id/api/page_banner"
        );
        const programCateringBanner = response.data.find(
          (banner) => banner.id === bannerId
        );
        setBannerData(programCateringBanner);
      } catch (err) {
        console.error("Error fetching banner data:", err);
      }
    };

    fetchPrograms();
    fetchBannerData();
  }, []);

  useEffect(() => {
    if (programs.length > 0) {
      setLoading(false);
    }
  }, [programs]);

  if (!bannerData) {
    return <div>Loading banner...</div>;
  }

  return (
    <div>
      <section
        className="relative w-full bg-cover bg-center h-[50vh] md:h-[400px]"
        style={{ backgroundImage: `url(${bannerData.image})` }}
      >
        <div className="relative w-full h-full md:hidden flex items-center justify-center bg-black bg-opacity-50 select-none">
          <div className="text-xl max-w-3xl p-4 text-center">
            <h2 className="text-white text-4xl font-bold">Program Catering</h2>
          </div>
        </div>
        <div className="hidden md:flex absolute inset-0 items-center">
          <div className="absolute inset-0 flex items-center justify-start pt-52 pl-8 bg-black bg-opacity-50 select-none">
            <div className="text-xl max-w-3xl p-10 text-wrap">
              <h2 className="text-white text-5xl font-bold">
                Program Catering
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="relative w-full bg-white py-24 px-6 select-none">
        <div className="text-center mb-16 md:hidden">
          <h3 className="text-2xl font-bold mb-8 text-wrap">
            Kami Memiliki Beberapa Program Catering, Diantaranya :
          </h3>
        </div>
        <div className="hidden md:flex text-center mb-16">
          <h3 className="text-5xl font-bold mb-8 text-wrap">
            Kami Memiliki Beberapa Program Catering, Diantaranya :
          </h3>
        </div>
        <div className="container mx-auto md:px-8">
          <div className="grid grid-cols-1 gap-12">
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : (
              programs.map((program) => (
                <div
                  key={program.id}
                  className="relative mx-auto rounded-lg overflow-visible bg-[#E5F5EC] shadow-lg w-full"
                >
                  {" "}
                  {/* Full width for each program */}
                  <div className="absolute top-[-20px] left-4 flex items-center justify-between md:h-16 bg-[#587868] text-white text-lg font-bold py-2 px-4 rounded-full z-10 gap-4">
                    <h4 className="text-md sm:text-lg md:text-2xl font-bold flex-shrink-0 text-left">
                      {program.title}
                    </h4>
                    <span className="text-md sm:text-lg md:text-2xl font-light flex-shrink-0 text-right">
                      ({program.calories} cal)
                    </span>
                  </div>
                  <div className="w-full p-6 flex-1">
                    {" "}
                    {/* Ensures the description spans full width */}
                    <p
                      className="text-lg text-gray-700 text-justify py-7 px-1.5"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(program.description),
                      }}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProgramCatering;
