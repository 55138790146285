import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const EditUser = () => {
    const [name_user, setName] = useState('');
    const [email_user, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`https://103.177.56.115:3000/api/users/${id}`);
                // console.log('Fetched user data:', response.data);
                const user = response.data;
                
                if (user) {
                    setName(user.name_user || '');
                    setEmail(user.email_user || '');
                    setSelectedRole(user.id_role || '');
                } else {
                    setError('User not found');
                }
            } catch (err) {
                setError('Failed to fetch user data.');
            }
        };

        const fetchRoles = async () => {
            try {
                const response = await axios.get('https://103.177.56.115:3000/api/roles');
                setRoles(response.data);
            } catch (err) {
                setError('Failed to fetch roles.');
            }
        };

        fetchUser();
        fetchRoles();
    }, [id]);

    const handleEdit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`https://103.177.56.115:3000/api/users/${id}`, {
                name_user,
                email_user,
                password,
                id_role: selectedRole
            });
            setSuccess(response.data.message);
            setTimeout(() => {
                navigate('/admin/dashboard');
            }, 2000);
        } catch (err) {
            if (err.response) {
                setError(err.response.data.error);
            } else {
                setError('Update failed. Please try again.');
            }
        }
    };

    const handleCancel = () => {
        navigate('/admin/dashboard'); // Navigate back to the dashboard
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md w-96">
                <h1 className="text-2xl font-semibold text-center mb-6">Edit User</h1>
                <form onSubmit={handleEdit}>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Name</label>
                        <input
                            type="text"
                            value={name_user}
                            onChange={(e) => setName(e.target.value)}
                            required
                            className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            value={email_user}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Role</label>
                        <select
                            value={selectedRole}
                            onChange={(e) => setSelectedRole(e.target.value)}
                            required
                            className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="">Select a role</option>
                            {roles.map(role => (
                                <option key={role.id_role} value={role.id_role}>
                                    {role.nama_role}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-6">
                        <label className="block text-sm font-medium text-gray-700">Password</label>
                        <div className="relative">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                {showPassword ? 'Hide' : 'Show'}
                            </button>
                        </div>
                    </div>
                    {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                    {success && <p className="text-green-500 text-sm mb-4">{success}</p>}
                    <div className="flex space-x-4">
                        <button
                            type="submit"
                            className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 focus:outline-none"
                        >
                            Update User
                        </button>
                        <button
                            type="button"
                            onClick={handleCancel}
                            className="w-full bg-gray-300 text-gray-700 py-2 rounded hover:bg-gray-400 focus:outline-none"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditUser;
