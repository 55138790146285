import React, { createContext, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import NotFound from "./pages/NotFound";
import { Header } from "./components/Header";
import Footer from "./components/Footer";
import Beranda from "./pages/Beranda";
import TentangKami from "./pages/TentangKami";
import ProgramCatering from "./pages/ProgramCatering";
import Menu from "./pages/Menu";
import CorporateMeals from "./pages/CorporateMeals";
import Kontak from "./pages/Kontak";
import Login from "./pages/admin/Login";
import Dashboard from "./pages/admin/Dashboard";
import PrivateRoute from "./PrivateRoute";
import Register from "./pages/admin/Register";
import EditUser from "./pages/admin/EditUser";
import AdminProgramCatering from "./pages/admin/AdminProgramCatering";
import axios from "axios";
import "./index.css";

// Create AuthContext
export const AuthContext = createContext();

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );
  const [links, setLinks] = useState({});
  const location = useLocation();

  // Fetch links from API
  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await axios.get("api.dermalicious.id/api/links");
        setLinks(response.data[0]); // Access the first object in the array
      } catch (error) {
        console.error("Failed to fetch links:", error);
      }
    };

    fetchLinks();
  }, []);

  // Set document title based on the route
  useEffect(() => {
    const titles = {
      "/": "Beranda - Dermalicious",
      "/tentang-kami": "Tentang Kami - Dermalicious",
      "/program-catering": "Program Catering - Dermalicious",
      "/menu": "Menu - Dermalicious",
      "/corporate-meals": "Corporate Meals - Dermalicious",
      "/kontak": "Kontak - Dermalicious",
      "/admin/login": "Admin Login - Dermalicious",
      "/admin/dashboard": "Dashboard - Dermalicious",
    };
    document.title = titles[location.pathname] || "Dermalicious";
  }, [location]);

  // List of valid routes
  const validRoutes = [
    "/",
    "/tentang-kami",
    "/program-catering",
    "/menu",
    "/corporate-meals",
    "/kontak",
    // '/admin/login',
    // '/admin/register',
    // '/admin/edit-user',
    // '/admin/program-catering',
    // '/admin/dashboard'
  ];

  // Check if the current route is valid
  const isValidRoute = validRoutes.some((route) => {
    // Handle dynamic parameter for edit-user route
    if (route.includes(":id")) {
      const regex = new RegExp(route.replace(":id", "[0-9]+"));
      return regex.test(location.pathname);
    }
    return location.pathname === route;
  });

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      <>
        {isValidRoute && <Header />}
        <main className="flex-1">
          <Routes>
            <Route path="/" element={<Beranda />} />
            <Route path="/tentang-kami" element={<TentangKami />} />
            <Route path="/program-catering" element={<ProgramCatering />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/corporate-meals" element={<CorporateMeals />} />
            <Route path="/kontak" element={<Kontak />} />
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/register" element={<Register />} />
            <Route path="/admin/edit-user/:id" element={<EditUser />} />
            <Route
              path="/admin/program-catering"
              element={<AdminProgramCatering />}
            />
            {/* Protect the Dashboard route */}
            <Route
              path="/admin/dashboard"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            {/* Wildcard route for 404 Not Found */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        {isValidRoute && <Footer links={links} />}
      </>
    </AuthContext.Provider>
  );
};

// Wrap the App component with Router
const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
