import React from "react";
import {
  FaWhatsapp,
  FaInstagram,
  FaTiktok,
  FaRegEnvelope,
} from "react-icons/fa";
import { navLinks } from "./NavLinks";

const Footer = ({ links }) => {
  return (
    <footer className="w-full bg-[#4a6a5a] p-8">
      <div className="container mx-auto px-4">
        {/* Logo */}
        <div className="flex justify-start mb-8">
          <img
            src="/logo_footer.png"
            alt="Logo Footer"
            className="w-40 h-auto object-contain"
          />
        </div>

        {/* Footer Links */}
        <div className="flex flex-col md:flex-row">
          {/* Office Section */}
          <div className="flex flex-col md:items-start mb-6 md:mb-0 md:w-2/5">
            <h4 className="text-xl text-white font-bold mb-2">Office</h4>
            <ul className="space-y-1 text-white">
              <li>
                Jl. Kendal No.6, RT.10/RW.6, Dukuh Atas, Menteng, Kec. Menteng
              </li>
              <li>Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10310</li>
            </ul>
            {/* Additional Contact Info */}
            <div className="flex flex-col mt-4 text-white">
              <div className="flex items-center space-x-2">
                <FaWhatsapp className="text-4xl" />
                <a
                  href={links?.whatsapp}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline text-xl"
                >
                  0813-9902-2776
                </a>
              </div>
            </div>
          </div>

          {/* Halaman Section */}
          <div className="flex flex-col md:items-start mb-6 md:mb-0 md:w-2/5 md:mx-40">
            <h4 className="text-xl text-white font-bold mb-2">Halaman</h4>
            <ul className="space-y-1 text-white">
              {navLinks.map((link) => (
                <li key={link.to}>
                  <a href={link.to} className="hover:underline">
                    {link.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Follow Us Section */}
          <div className="flex flex-col md:items-start md:w-1/3">
            <h4 className="text-xl text-white font-bold mb-2">Follow Us</h4>
            <div className="flex space-x-4 text-white mb-6">
              <a
                href={links?.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="text-4xl hover:text-gray-300"
              >
                <FaInstagram />
              </a>
              <a
                href={links?.tiktok}
                target="_blank"
                rel="noopener noreferrer"
                className="text-4xl hover:text-gray-300"
              >
                <FaTiktok />
              </a>
            </div>

            {/* Subscribe Section */}
            <div className="flex flex-col md:items-start">
              <h4 className="text-xl text-white font-bold mb-2">Subscribe</h4>
              <div className="flex items-center">
                <div className="p-2 bg-[#E5F5EC] rounded-l-lg">
                  <FaRegEnvelope className="text-2xl text-[#4a6a5a]" />
                </div>
                <form className="flex space-x-2">
                  <input
                    type="email"
                    placeholder="Your e-mail address"
                    className="p-2 rounded-r-lg text-center text-gray-600 placeholder-gray-300 focus:outline-none"
                    required
                  />
                  {/* <button
                    type="submit"
                    className="bg-[#ffffff] text-[#4a6a5a] py-2 px-4 rounded-md hover:bg-gray-200"
                  >
                    Subscribe
                  </button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
