import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUserCircle, FaChevronDown, FaBars, FaTimes } from 'react-icons/fa'; 
import axios from 'axios';
import AdminProgramCatering from './AdminProgramCatering';
import AdminLinks from './AdminLinks';
import AdminEazyMeals from './AdminEazyMeals';
import AdminTestimonials from './AdminTestimonials';
import AdminPartnerships from './AdminPartnerships';
import AdminPageBanner from './AdminPageBanner';

const Dashboard = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [homepageDropdownOpen, setHomepageDropdownOpen] = useState(false);
    const [kontakDropdownOpen, setKontakDropdownOpen] = useState(false);
    const [settingDropdownOpen, setSettingDropdownOpen] = useState(false);
    const [showUsers, setShowUsers] = useState(false);
    const [showProgramCatering, setShowProgramCatering] = useState(false);
    const [showLink, setShowLink] = useState(false);
    const [showEazyMeals, setShowEazyMeals] = useState(false);
    const [showTestimonials, setShowTestimonials] = useState(false);
    const [showPartnerships, setShowPartnerships] = useState(false);
    const [showPageBanner, setShowPageBanner] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('https://103.177.56.115:3000/api/current-user', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setCurrentUser(response.data);
            } catch (err) {
                if (err.response && err.response.status === 403) {
                    // Redirect to login page on 403 Forbidden error
                    navigate('/admin/login');
                } else {
                    setError('Failed to fetch current user');
                }
            }
        };
        fetchCurrentUser();
    }, [navigate]);
    

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://103.177.56.115:3000/api/users');
                if (Array.isArray(response.data)) {
                    setUsers(response.data);
                } else {
                    setError('Unexpected response format');
                }
            } catch (err) {
                setError('Failed to fetch users');
            } finally {
                setLoading(false);
            }
        };

        if (showUsers) fetchUsers();
    }, [showUsers]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        setCurrentUser(null);
        navigate('/admin/login');
    };

    // const handleDelete = async (userId) => {
    //     const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    //     if (!confirmDelete) return;

    //     try {
    //         await axios.delete(`http://localhost:5000/api/users/${userId}`);
    //         setUsers(users.filter(user => user.id !== userId));
    //     } catch (error) {
    //         setError('Failed to delete user.');
    //     }
    // };

    // const handleDelete = async (userId) => {
    //     const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    //     if (!confirmDelete) return;

    //     try {
    //         await axios.delete(`http://localhost:5000/api/users/${userId}`);
    //         setUsers((prevUsers) => prevUsers.filter(user => user.id_user !== userId));
    //     } catch (error) {
    //         setError('Failed to delete user.');
    //     }
    // };

    const handleDelete = async (userId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this user?");
        if (!confirmDelete) return;
    
        try {
            await axios.delete(`https://103.177.56.115:3000/api/users/${userId}`);
            setUsers((prevUsers) => prevUsers.filter(user => user.id_user !== userId));
        } catch (error) {
            console.error('Delete user error:', error); // Log the error
            setError('Failed to delete user.');
        }
    };
    
    

    return (
        <div className="flex flex-col h-screen">
            {/* Navbar */}
            <div className="flex justify-between items-center bg-white drop-shadow-lg p-4">
                <img 
                    src="/logo_dermalicious.png" 
                    alt="Logo Dermalicious" 
                    className="h-[50px] w-[150px] object-contain" 
                />
                <div className="flex items-center">
                    <h2 className="mr-4 text-lg">Hi, {currentUser ? currentUser.name_user : 'Admin'}</h2>
                    <div className="relative">
                        <button onClick={() => setDropdownOpen(!dropdownOpen)} className="text-gray-600 hover:text-gray-800">
                            <FaUserCircle className="text-3xl" />
                        </button>
                        {dropdownOpen && (
                            <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md">
                                <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100">Logout</button>
                            </div>
                        )}
                    </div>
                </div>
                <button onClick={() => setSidebarOpen(!sidebarOpen)} className="md:hidden text-gray-600">
                    {sidebarOpen ? <FaTimes className="text-2xl" /> : <FaBars className="text-2xl" />}
                </button>
            </div>

            <div className="flex flex-1">
                {/* Sidebar */}
                <aside className={`fixed inset-0 md:relative md:block w-64 bg-white shadow-lg transition-transform duration-300 ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0`}>
                    <nav className="mt-6 px-3">
                        <ul>
                            {/* <li><Link to="/admin/dashboard" className="block p-4 hover:bg-gray-100 text-base text-gray-700 font-medium">Home</Link></li> */}
                            {currentUser && currentUser.nama_role === 'Super Admin' && (
                                <li onClick={() => { 
                                    setShowUsers(true); 
                                    setShowProgramCatering(false); 
                                    setShowTestimonials(false);
                                    setShowPartnerships(false);
                                    setShowLink(false);
                                    setShowEazyMeals(false);
                                    setShowPageBanner(false);
                                }}>
                                    <Link to="#" className="block p-4 hover:bg-gray-100 text-base text-gray-700 font-medium">Users</Link>
                                </li>
                            )}
                            <li>
                                <div onClick={() => setHomepageDropdownOpen(!homepageDropdownOpen)} className="cursor-pointer flex items-center justify-between p-4 hover:bg-gray-100 text-base text-gray-700 font-medium">
                                    <span>Homepage</span>
                                    <FaChevronDown className={`ml-2 transition-transform ${homepageDropdownOpen ? 'transform rotate-180' : ''}`} />
                                </div>
                                {homepageDropdownOpen && (
                                    <ul className="pl-6">
                                        <li onClick={() => { 
                                            setShowUsers(false); 
                                            setShowProgramCatering(true); 
                                            setShowTestimonials(false);
                                            setShowPartnerships(false);
                                            setShowLink(false);
                                            setShowEazyMeals(false);
                                            setShowPageBanner(false);
                                        }}>
                                            <Link to="#" className="block p-4 hover:bg-gray-100 text-base text-gray-700 font-medium">Program Catering</Link>
                                        </li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <div onClick={() => setKontakDropdownOpen(!kontakDropdownOpen)} className="cursor-pointer flex items-center justify-between p-4 hover:bg-gray-100 text-base text-gray-700 font-medium">
                                    <span>Kontak</span>
                                    <FaChevronDown className={`ml-2 transition-transform ${kontakDropdownOpen ? 'transform rotate-180' : ''}`} />
                                </div>
                                {kontakDropdownOpen && (
                                    <ul className="pl-6">
                                        <li onClick={() => { 
                                            setShowUsers(false); 
                                            setShowProgramCatering(false);
                                            setShowLink(true);
                                            setShowTestimonials(false);
                                            setShowPartnerships(false);
                                            setShowEazyMeals(false);
                                            setShowPageBanner(false);
                                        }}>
                                            <Link to="#" className="block p-4 hover:bg-gray-100 text-base text-gray-700 font-medium">Link</Link>
                                        </li>
                                        <li onClick={() => { 
                                            setShowUsers(false); 
                                            setShowProgramCatering(false);
                                            setShowLink(false);
                                            setShowTestimonials(false);
                                            setShowPartnerships(false);
                                            setShowEazyMeals(true);
                                            setShowPageBanner(false);
                                        }}>
                                            <Link to="#" className="block p-4 hover:bg-gray-100 text-base text-gray-700 font-medium">Eazy Meals</Link>
                                        </li>
                                    </ul>
                                )}
                            </li>
                            <li onClick={() => { 
                                setShowUsers(false); 
                                setShowProgramCatering(false); 
                                setShowLink(false);
                                setShowTestimonials(true);
                                setShowPartnerships(false);
                                setShowEazyMeals(false);
                                setShowPageBanner(false);
                            }}>
                                <Link to="#" className="block p-4 hover:bg-gray-100 text-base text-gray-700 font-medium">Testimonials</Link>
                            </li>
                            <li onClick={() => { 
                                setShowUsers(false); 
                                setShowProgramCatering(false); 
                                setShowLink(false);
                                setShowTestimonials(false);
                                setShowPartnerships(true);
                                setShowEazyMeals(false);
                                setShowPageBanner(false);
                            }}>
                                <Link to="#" className="block p-4 hover:bg-gray-100 text-base text-gray-700 font-medium">Partnerships</Link>
                            </li>
                            <li>
                                <div onClick={() => setSettingDropdownOpen(!settingDropdownOpen)} className="cursor-pointer flex items-center justify-between p-4 hover:bg-gray-100 text-base text-gray-700 font-medium">
                                    <span>Setting</span>
                                    <FaChevronDown className={`ml-2 transition-transform ${settingDropdownOpen ? 'transform rotate-180' : ''}`} />
                                </div>
                                {settingDropdownOpen && (
                                    <ul className="pl-6">
                                        <li onClick={() => { 
                                            setShowUsers(false); 
                                            setShowProgramCatering(false); 
                                            setShowTestimonials(false);
                                            setShowPartnerships(false);
                                            setShowLink(false);
                                            setShowEazyMeals(false);
                                            setShowPageBanner(true);
                                            }}>
                                            <Link to="#" className="block p-4 hover:bg-gray-100 text-base text-gray-700 font-medium">Page Banner</Link>
                                        </li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </nav>
                </aside>

                {/* Main Content */}
                <div className={`flex-1 bg-gray-100 p-6 overflow-x-auto ${sidebarOpen ? 'ml-64' : ''}`}>
                    {showUsers ? (
                        <>
                            {/* <h2 className="text-2xl font-semibold mb-4">Users</h2> */}
                            <h2 className="text-2xl font-semibold flex justify-between items-center pb-4">
                                <span>Users</span>
                                <Link to="/admin/register" className="bg-blue-600 text-white text-lg font-normal px-4 py-3 rounded hover:bg-blue-700 transition">
                                    New
                                </Link>
                            </h2>
                            {loading ? (
                                <p>Loading...</p>
                            ) : error ? (
                                <p className="text-red-500">{error}</p>
                            ) : (
                                <div className="bg-white rounded shadow-md overflow-hidden">
                                    <div className="overflow-x-auto">
                                        <table className="min-w-full border-collapse">
                                            <thead>
                                                <tr className="bg-gray-200 text-gray-600">
                                                    <th className="py-3 px-4 text-left">ID</th>
                                                    <th className="py-3 px-4 text-left">Name</th>
                                                    <th className="py-3 px-4 text-left">Email</th>
                                                    <th className="py-3 px-4 text-left">Role</th>
                                                    <th className="py-3 px-4 text-left">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.map((user) => (
                                                    <tr key={user.id_user} className="hover:bg-gray-100">
                                                        <td className="border px-4 py-2">{user.id_user}</td>
                                                        <td className="border px-4 py-2">{user.name_user}</td>
                                                        <td className="border px-4 py-2">{user.email_user}</td>
                                                        <td className="border px-4 py-2">{user.nama_role}</td>
                                                        <td className="border px-4 py-2">
                                                            <Link to={`/admin/edit-user/${user.id_user}`} className="text-blue-500 hover:underline">Edit</Link>
                                                            <button onClick={() => handleDelete(user.id_user)} className="text-red-500 hover:underline ml-2">Delete</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </>
                        ) : showPartnerships ? (
                            <AdminPartnerships />
                        ) : showPageBanner ? (
                            <AdminPageBanner />
                        ) : showTestimonials ? (
                            <AdminTestimonials />
                        ) : showLink ? (
                            <AdminLinks />
                        ) : showEazyMeals ? (
                            <AdminEazyMeals />
                        ) : showProgramCatering ? (
                            <AdminProgramCatering />
                        ) : (
                            <p>Select "Menu" to view the data list.</p>
                        )}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
