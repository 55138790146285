import React, { useEffect, useState } from "react";
import axios from "axios";
import TestimonialCarousel from "../components/TestimonialCarousel";
import PartnershipCarousel from "../components/PartnershipCarousel";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

const Beranda = () => {
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(
          "https://api.dermalicious.id/api/program-catering"
        );
        if (Array.isArray(response.data)) {
          setPrograms(response.data);
        } else {
          setError("Unexpected response format");
        }
      } catch (err) {
        setError("Failed to fetch programs");
      } finally {
        setLoading(false);
      }
    };
    fetchPrograms();
  }, []);
  return (
    <div>
      {/* Full-width Image Section */}
      <section
        className="relative w-full h-screen bg-cover bg-center"
        style={{ backgroundImage: "url(/banner_homepage.jpg)" }}
      >
        <div className="absolute inset-0 flex items-center justify-start p-16 bg-black bg-opacity-50">
          <div className="text-xl max-w-3xl p-10 text-wrap">
            <h2 className="text-white text-5xl font-bold mb-4">
              Join Our Program
            </h2>
            <p className="text-white text-2xl mt-8 mb-8 text-left tracking-wider text-wrap ">
              Solusi catering sehat untuk bantu kamu turunkan berat badan tetap
              ideal tanpa ribet & menyiksa. Plus anti aging, kulit, kuku, rambut
              dan tubuhmu jadi lebih sehat serta menormalkan kolestrol dan gula
              darah.
            </p>
            <button className="bg-[#587868] text-white font-medium px-7 py-4 rounded-lg hover:bg-[#4a6a5a]">
              Order Sekarang
            </button>
          </div>
        </div>
      </section>

      {/* Tentang Kami Section */}
      <section className="relative w-full bg-white py-24">
        <div className="container gap-12 mx-auto flex flex-col md:flex-row items-center justify-between px-8">
          {/* Left Side: Image */}
          <div className="w-full md:w-1/2 mb-8 md:mb-0 flex justify-center">
            <img
              src="/banner_tentangkami.jpg"
              alt="Additional Content"
              className="w-full h-auto md:w-[550px] md:h-[650px] object-cover rounded-lg shadow-lg md:max-w-full md:max-h-full max-w-[350px] max-h-[450px]"
            />
          </div>

          {/* Right Side: Text Content */}
          <div className="w-full md:w-1/2 text-center md:text-left select-none">
            <h3 className="text-3xl md:text-5xl font-bold mb-8">
              Tentang Kami
            </h3>
            <p className="text-lg text-slate-500 text-justify tracking-wide mb-6">
              PT. Bumi Estetika Bahagia Gemilang adalah usaha yang bergerak
              sebagai penyedia jasa makanan sehat dengan menu makan yang
              bervariatif, lezat, dan anti hambar. Komposisi terukur oleh
              Clinical Nutrition Doctor, dan diolah langsung oleh gourmet chef
              yang bersertifikat. Kami juga memiliki Sertifikasi HACCP dan
              Sertifikasi Pelatihan Keamanan Siap Saji sebagai pengawasan
              jaminan mutu dan standar keamanan produk yang menjadi jaminan
              makanan kami aman untuk dikonsumsi.
            </p>
            <Link to="/tentang-kami">
              <button className="bg-[#4a6a5a] text-white font-medium h-16 px-12 mt-6 rounded-lg hover:bg-[#3b5d4a]">
                Read More
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* Program Catering Section */}
      <section className="relative w-full bg-[#E5F5EC] py-16 select-none">
        <div className="text-center mb-16">
          <h3 className="text-3xl md:text-5xl font-bold mb-8 mx-auto">
            Program Catering Dermalicious
          </h3>
        </div>
        <div className="container mx-auto px-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : (
              programs.slice(0, 3).map((program) => (
                <div
                  key={program.id}
                  className="max-w-xl mx-auto rounded-lg p-4 overflow-hidden bg-white shadow-lg"
                >
                  <img
                    className="w-full h-[230px] object-cover rounded-lg"
                    src={program.image}
                    alt={program.title}
                  />
                  <div className="py-6">
                    <div className="flex items-center justify-between mb-2">
                      <div className="font-bold text-2xl">{program.title}</div>
                      <span className="text-[#587868] text-md font-bold">
                        ({program.calories} cal)
                      </span>
                    </div>
                    <p
                      className="text-gray-700 text-justify"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(program.description),
                      }}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="text-center mt-6 mb-6">
          <Link to="/program-catering">
            <button
              type="button"
              className="bg-[#4a6a5a] text-white font-medium rounded-lg text-center inline-flex items-center h-16 px-12 mt-6 rounded-lg hover:bg-[#3b5d4a]"
            >
              See More
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          </Link>
        </div>
      </section>

      {/* Menu Section */}
      <section className="relative w-full bg-white py-16 select-none">
        <div className="flex flex-col md:flex-row items-center justify-between px-12">
          <div className="flex flex-col items-center md:items-start mb-8 md:mb-0">
            <h3 className="text-3xl font-bold md:hidden">Menu Dermalicious</h3>
            <h3 className="text-5xl font-bold mb-8 text-wrap hidden md:block">
              Menu Dermalicious
            </h3>
          </div>
          <div className="hidden md:flex items-center">
            <Link to="/menu">
              <button
                type="button"
                className="bg-[#4a6a5a] text-white font-medium rounded-lg text-center inline-flex items-center h-16 px-12 hover:bg-[#3b5d4a]"
              >
                See More
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </button>
            </Link>
          </div>
        </div>
        <div className="container mx-auto px-8 mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Image 1 */}
          <div className="relative max-w-xl mx-auto rounded-lg overflow-hidden group">
            <img
              src="/indonesian_food.jpg"
              className="w-full h-auto object-cover rounded-lg transition-opacity duration-300 ease-in-out opacity-70 group-hover:opacity-100"
              alt="Indonesian Food"
            />
            <div className="absolute inset-0 flex items-center justify-center transition-all duration-300 ease-in-out group-hover:items-end">
              <div className="text-white text-center text-2xl font-semibold px-4 py-2 rounded-lg bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-300 ease-in-out">
                Indonesian Food
              </div>
            </div>
          </div>
          {/* Image 2 */}
          <div className="relative max-w-xl mx-auto rounded-lg overflow-hidden group">
            <img
              src="/asian_food.jpg"
              className="w-full h-auto object-cover rounded-lg transition-opacity duration-300 ease-in-out opacity-70 group-hover:opacity-100"
              alt="Asian Food"
            />
            <div className="absolute inset-0 flex items-center justify-center transition-all duration-300 ease-in-out group-hover:items-end">
              <div className="text-white text-center text-2xl font-semibold px-4 py-2 rounded-lg bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-300 ease-in-out">
                Asian Food
              </div>
            </div>
          </div>
          {/* Image 3 */}
          <div className="relative max-w-xl mx-auto rounded-lg overflow-hidden group">
            <img
              src="/western_food.jpg"
              className="w-full h-auto object-cover rounded-lg transition-opacity duration-300 ease-in-out opacity-70 group-hover:opacity-100"
              alt="Western Food"
            />
            <div className="absolute inset-0 flex items-center justify-center transition-all duration-300 ease-in-out group-hover:items-end">
              <div className="text-white text-center text-2xl font-semibold px-4 py-2 rounded-lg bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-300 ease-in-out">
                Western Food
              </div>
            </div>
          </div>
        </div>
        {/* Button only visible on small screens */}
        <div className="flex justify-center mt-6 md:hidden">
          <Link to="/menu">
            <button
              type="button"
              className="bg-[#4a6a5a] text-white font-medium rounded-lg text-center inline-flex items-center h-16 px-12 hover:bg-[#3b5d4a]"
            >
              See More
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          </Link>
        </div>
      </section>

      {/* Carousel Testimoni Area */}
      {/* <section className="relative w-full bg-[#E5F5EC] py-16"> */}
      <TestimonialCarousel />
      {/* </section> */}

      <section className="relative w-full bg-white py-16">
        <PartnershipCarousel />
      </section>
    </div>
  );
};

export default Beranda;
