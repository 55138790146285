import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DOMPurify from 'dompurify';

Modal.setAppElement('#root');

const AdminProgramCatering = () => {
    const [programs, setPrograms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formData, setFormData] = useState({ title: '', calories: '', description: '' });
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [editingId, setEditingId] = useState(null);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        const fetchPrograms = async () => {
            try {
                const response = await axios.get('https://103.177.56.115:3000/api/program-catering');
                if (Array.isArray(response.data)) {
                    setPrograms(response.data);
                } else {
                    setError('Unexpected response format');
                }
            } catch (err) {
                setError('Failed to fetch programs');
            } finally {
                setLoading(false);
            }
        };
        fetchPrograms();
    }, []);

    const openModal = (program = null) => {
        if (program) {
            setFormData(program);
            setImageFile(null);
            setImagePreview(program.image);
            setIsEditMode(true);
            setEditingId(program.id);
        } else {
            setFormData({ title: '', calories: '', description: '' });
            setImageFile(null);
            setImagePreview('');
            setIsEditMode(false);
        }
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDescriptionChange = (event, editor) => {
        const data = editor.getData();
        setFormData({ ...formData, description: data });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (!file.type.startsWith('image/')) {
                setError('Please upload a valid image file');
                return;
            }
            setError(''); // Clear error if the file is valid
            setImageFile(file);
            const previewUrl = URL.createObjectURL(file);
            setImagePreview(previewUrl);
        } else {
            setImagePreview('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSubmit = new FormData();
        formDataToSubmit.append('title', formData.title);
        if (formData.calories) formDataToSubmit.append('calories', formData.calories);
        if (formData.description) formDataToSubmit.append('description', formData.description);
        if (imageFile) {
            formDataToSubmit.append('image', imageFile);
        }

        try {
            if (isEditMode) {
                await axios.put(`https://103.177.56.115:3000/api/program-catering/${editingId}`, formDataToSubmit, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
            } else {
                await axios.post('https://103.177.56.115:3000/api/program-catering', formDataToSubmit, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
            }
            closeModal();
            const response = await axios.get('https://103.177.56.115:3000/api/program-catering');
            setPrograms(response.data);
        } catch (error) {
            setError('Failed to save program');
        }
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this program?");
        if (!confirmDelete) return;

        try {
            await axios.delete(`https://103.177.56.115:3000/api/program-catering/${id}`);
            setPrograms(programs.filter(program => program.id !== id)); // Remove from state
        } catch (error) {
            setError('Failed to delete program');
        }
    };

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = programs.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(programs.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">Program Catering</h2>
                <button 
                    onClick={() => openModal()} 
                    className="bg-blue-600 text-white px-6 py-3 rounded hover:bg-blue-700 transition"
                >
                    New
                </button>
            </div>
    
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : programs.length === 0 ? (
                <p>No data entries</p>
            ) : (
                <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                        <tr className="bg-gray-200 text-gray-600">
                            <th className="py-3 px-4 text-left">No</th>
                            <th className="py-3 px-4 text-left">Title</th>
                            <th className="py-3 px-4 text-left">Calories</th>
                            <th className="py-3 px-4 text-left">Image</th>
                            <th className="py-3 px-4 text-left">Description</th>
                            <th className="py-3 px-4 text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((program, index) => (
                            <tr key={program.id} className="hover:bg-gray-100">
                                <td className="border px-4 py-2">{index + 1 + indexOfFirstItem}</td>
                                <td className="border px-4 py-2">{program.title}</td>
                                <td className="border px-4 py-2">{program.calories}</td>
                                <td className="border px-4 py-2">
                                    <img src={program.image} alt={program.title} className="w-16 h-16 object-cover" />
                                </td>
                                {/* <td className="border px-4 py-2 line-clamp-3 md:line-clamp-none">{program.description}</td> */}
                                <td className="border px-4 py-2 line-clamp-3 md:line-clamp-none" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(program.description) }} />

                                <td className="border px-4 py-2">
                                    <button onClick={() => openModal(program)} className="text-blue-500 hover:underline">
                                        Edit
                                    </button>
                                    <button onClick={() => handleDelete(program.id)} className="text-red-500 hover:underline ml-2">
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <div className="flex justify-between mt-4">
                <button onClick={handlePrevPage} disabled={currentPage === 1} className="bg-gray-300 p-2 rounded hover:bg-gray-400">
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages} className="bg-gray-300 p-2 rounded hover:bg-gray-400">
                    Next
                </button>
            </div>
            <Modal 
                isOpen={modalIsOpen} 
                onRequestClose={closeModal} 
                className="modal"
                overlayClassName="overlay"
            >
                <h2 className="text-xl font-bold mb-4">{isEditMode ? 'Edit Program Catering' : 'Add Program Catering'}</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium">Title</label>
                        <input 
                            type="text" 
                            name="title" 
                            value={formData.title} 
                            onChange={handleChange} 
                            required 
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">Calories</label>
                        <input 
                            type="text" 
                            name="calories" 
                            value={formData.calories} 
                            onChange={handleChange} 
                            className="border border-gray-300 rounded p-2 w-full" // Make this optional
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">Upload Image</label>
                        <input 
                            type="file" 
                            accept="image/*" 
                            onChange={handleFileChange} 
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                        {imagePreview && <img src={imagePreview} alt="Preview" className="mt-2 w-32 h-32 object-cover" />}
                    </div>
                    <div>
                        {/* <label className="block text-sm font-medium">Description</label>
                        <textarea 
                            name="description" 
                            value={formData.description} 
                            onChange={handleChange} 
                            className="border border-gray-300 rounded p-2 w-full" // Make this optional
                        /> */}
                        <label className="block text-sm font-medium">Description</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={formData.description}
                            onChange={handleDescriptionChange}
                        />
                    </div>
                    <div className="flex justify-end">
                        <button 
                            type="submit" 
                            className="bg-green-600 text-white p-2 rounded hover:bg-green-700 transition mr-2"
                        >
                            {isEditMode ? 'Update' : 'Create'}
                        </button>
                        <button 
                            type="button" 
                            onClick={closeModal} 
                            className="bg-gray-300 text-gray-700 p-2 rounded hover:bg-gray-400 transition"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    );
    
};

export default AdminProgramCatering;
