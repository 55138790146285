import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const AdminEazyMeals = () => {
    const [meals, setMeals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formData, setFormData] = useState({ name: '', image: null, url: '' });
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [dimensionError, setDimensionError] = useState('');

    useEffect(() => {
        fetchMeals();
    }, []);

    const fetchMeals = async () => {
        try {
            const response = await axios.get('https://103.177.56.115:3000/api/eazy_meals');
            setMeals(Array.isArray(response.data) ? response.data : []);
        } catch {
            setError('Failed to fetch meals');
        } finally {
            setLoading(false);
        }
    };

    const openModal = (meal = null) => {
        if (meal) {
            setFormData({ name: meal.name, url: meal.url, image: meal.image });
            setImagePreview(meal.image);
            setIsEditMode(true);
            setEditingId(meal.id);
        } else {
            setFormData({ name: '', url: '', image: null });
            setImagePreview('');
            setIsEditMode(false);
            setEditingId(null);
        }
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setFormData({ name: '', url: '', image: null });
        setImagePreview('');
        setIsEditMode(false);
        setEditingId(null);
        setDimensionError(''); // Reset dimension error
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                if (img.width === 3001 && img.height === 695) {
                    setImageFile(file);
                    setImagePreview(URL.createObjectURL(file));
                    setDimensionError(''); // Clear any previous dimension error
                } else {
                    setDimensionError('Image must be 3001x695 pixels.');
                }
            };
        } else {
            setImagePreview('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (dimensionError) return; // Prevent submission if there's a dimension error

        const formDataToSubmit = new FormData();
        formDataToSubmit.append('name', formData.name);
        formDataToSubmit.append('url', formData.url);
        if (imageFile) formDataToSubmit.append('image', imageFile);
        try {
            if (isEditMode) {
                await axios.put(`https://103.177.56.115:3000/api/eazy_meals/${editingId}`, formDataToSubmit);
            } else {
                await axios.post('https://103.177.56.115:3000/api/eazy_meals', formDataToSubmit);
            }
            closeModal();
            fetchMeals();
        } catch {
            setError('Failed to save meal');
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this meal?")) {
            try {
                await axios.delete(`https://103.177.56.115:3000/api/eazy_meals/${id}`);
                setMeals(meals.filter(meal => meal.id !== id));
            } catch {
                setError('Failed to delete meal');
            }
        }
    };

    const indexOfLastMeal = currentPage * itemsPerPage;
    const indexOfFirstMeal = indexOfLastMeal - itemsPerPage;
    const currentMeals = meals.slice(indexOfFirstMeal, indexOfLastMeal);
    const totalPages = Math.ceil(meals.length / itemsPerPage);

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">Eazy Meals</h2>
                <button onClick={() => openModal()} className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition">
                    New Meal
                </button>
            </div>

            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : meals.length === 0 ? (
                <p>No meals available.</p>
            ) : (
                <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                        <tr className="bg-gray-200 text-gray-600">
                            <th className="py-3 px-4 text-left">No</th>
                            <th className="py-3 px-4 text-left">Name</th>
                            <th className="py-3 px-4 text-left">Image</th>
                            <th className="py-3 px-4 text-left">URL</th>
                            <th className="py-3 px-4 text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentMeals.map((meal, index) => (
                            <tr key={meal.id} className="hover:bg-gray-100">
                                <td className="border px-4 py-2">{index + 1 + indexOfFirstMeal}</td>
                                <td className="border px-4 py-2">{meal.name}</td>
                                <td className="border px-4 py-2">
                                    {meal.image && <img src={meal.image} alt={meal.name} className="h-16 object-cover" />}
                                </td>
                                <td className="border px-4 py-2">{meal.url}</td>
                                <td className="border px-4 py-2">
                                    <button onClick={() => openModal(meal)} className="text-blue-500 hover:underline">Edit</button>
                                    <button onClick={() => handleDelete(meal.id)} className="text-red-500 hover:underline ml-2">Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            <div className="flex justify-between items-center mt-4">
                <button 
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} 
                    disabled={currentPage === 1}
                    className="bg-gray-300 p-2 rounded hover:bg-gray-400 transition"
                >
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button 
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} 
                    disabled={currentPage === totalPages}
                    className="bg-gray-300 p-2 rounded hover:bg-gray-400 transition"
                >
                    Next
                </button>
            </div>

            <Modal 
                isOpen={modalIsOpen} 
                onRequestClose={closeModal} 
                className="modal"
                overlayClassName="overlay"
            >
                <h2 className="text-xl font-bold mb-4">{isEditMode ? 'Edit Meal' : 'Add Meal'}</h2>
                <form onSubmit={handleSubmit} className="flex flex-col h-full">
                    <div className="flex-grow overflow-y-auto">
                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium">Name</label>
                                <input 
                                    type="text" 
                                    name="name" 
                                    value={formData.name} 
                                    onChange={handleChange} 
                                    required 
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium">URL</label>
                                <input 
                                    type="url" 
                                    name="url" 
                                    value={formData.url} 
                                    onChange={handleChange} 
                                    required 
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium">Upload Image</label>
                                <input 
                                    type="file" 
                                    accept="image/*" 
                                    onChange={handleFileChange} 
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                {dimensionError && <p className="text-red-500">{dimensionError}</p>}
                                {imagePreview && <img src={imagePreview} alt="Preview" className="mt-2 h-24 object-cover" />}
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition">
                        {isEditMode ? 'Update Meal' : 'Add Meal'}
                    </button>
                </form>
            </Modal>
        </div>
    );
};

export default AdminEazyMeals;
