// src/components/TestimonialCarousel.jsx

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const TestimonialCarousel = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(
          "https://api.dermalicious.id/api/testimonials"
        );
        setTestimonials(response.data);
      } catch (err) {
        setError("Failed to fetch testimonials");
      } finally {
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    // <section className="relative w-full bg-[#E5F5EC]">
    //   <div className="text-center mb-16">
    //     <h3 className="text-3xl font-bold md:hidden">Testimoni</h3>
    //     <h3 className="text-5xl font-bold mb-8 text-wrap hidden md:block">Testimoni</h3>
    //   </div>
    //   <div className="container mx-auto px-24 md:px-12 relative">
    //     <Swiper
    //       modules={[Navigation]}
    //       spaceBetween={10}
    //       loop={true}
    //       navigation={{
    //         prevEl: '.swiper-button-prev',
    //         nextEl: '.swiper-button-next',
    //       }}
    //       className="swiper-container"
    //     >
    //       {testimonials.map((testimonial, index) => (
    //         <SwiperSlide key={index}>
    //           <div className="flex flex-col sm:flex-row bg-transparent rounded-lg overflow-hidden max-w-4xl mx-auto">
    //             <div className="w-full sm:w-1/3">
    //               <img
    //                 src={testimonial.image}
    //                 className="w-[400px] h-[400px] object-contain rounded-lg"
    //                 alt={`Testimonial from ${testimonial.name}`}
    //               />
    //             </div>
    //             <div className="px-8 flex flex-col justify-stretch w-full sm:w-2/3">
    //               <h3 className="text-3xl font-bold mb-4">{testimonial.name}</h3>
    //               <p className="text-gray-500 dark:text-gray-400 font-semibold mb-4">{testimonial.role}</p>
    //               <div className="flex items-center mb-4">
    //                 {[...Array(testimonial.rating)].map((_, i) => (
    //                   <svg key={i} className="w-8 h-8 text-yellow-400" fill="currentColor" viewBox="0 0 24 24">
    //                     <path d="M12 2l2.4 4.8 5.2.8-3.8 3.8.9 5.2-4.7-2.5-4.7 2.5.9-5.2-3.8-3.8 5.2-.8z"/>
    //                   </svg>
    //                 ))}
    //               </div>
    //               <p className="font-normal text-gray-500 dark:text-gray-400 text-justify">{testimonial.text}</p>
    //             </div>
    //           </div>
    //         </SwiperSlide>
    //       ))}
    //     </Swiper>
    //     {/* Navigation Buttons */}
    //     <div
    //       className="swiper-button-prev absolute top-1/2 left-4 z-10 cursor-pointer rounded-full flex items-center justify-center"
    //       style={{ backgroundColor: '#6e6e6e', width: '3rem', height: '3rem' }}
    //     >
    //       <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    //         <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14M5 12l4-4m-4 4 4 4"/>
    //       </svg>
    //     </div>
    //     <div
    //       className="swiper-button-next absolute top-1/2 right-4 z-10 cursor-pointer rounded-full flex items-center justify-center"
    //       style={{ backgroundColor: '#6e6e6e', width: '3rem', height: '3rem' }}
    //     >
    //       <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    //         <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
    //       </svg>
    //     </div>
    //   </div>
    // </section>
    <section className="relative w-full bg-[#E5F5EC] py-16">
      <div className="text-center mb-16">
        <h3 className="text-3xl font-bold md:hidden">Testimoni</h3>
        <h3 className="text-5xl font-bold mb-8 hidden md:block">Testimoni</h3>
      </div>
      <div className="container mx-auto px-8 md:px-12 relative">
        <Swiper
          modules={[Navigation]}
          spaceBetween={20}
          loop={true}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          className="swiper-container"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="flex flex-col sm:flex-row bg-white rounded-lg shadow-md overflow-hidden max-w-4xl mx-auto transition-transform duration-300 hover:scale-105">
                <div className="w-full sm:w-1/3">
                  <img
                    src={testimonial.image}
                    className="w-full h-full object-cover rounded-l-lg"
                    alt={`Testimonial from ${testimonial.name}`}
                  />
                </div>
                <div className="p-8 flex flex-col justify-between w-full sm:w-2/3">
                  <h3 className="text-2xl font-bold mb-2 text-gray-800">
                    {testimonial.name}
                  </h3>
                  <p className="text-gray-500 font-semibold mb-4">
                    {testimonial.role}
                  </p>
                  <div className="flex items-center mb-4">
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <svg
                        key={i}
                        className="w-6 h-6 text-yellow-400"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 2l2.4 4.8 5.2.8-3.8 3.8.9 5.2-4.7-2.5-4.7 2.5.9-5.2-3.8-3.8 5.2-.8z" />
                      </svg>
                    ))}
                  </div>
                  <p className="text-gray-600 text-justify">
                    {testimonial.text}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* Navigation Buttons */}
        <div
          className="swiper-button-prev absolute top-1/2 left-4 transform -translate-y-1/2 z-10 cursor-pointer rounded-full bg-gray-800 hover:bg-gray-700 p-2 transition-colors duration-300"
          style={{ width: "3rem", height: "3rem" }}
        >
          <svg
            className="w-6 h-6 text-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 12h14M5 12l4-4m-4 4 4 4"
            />
          </svg>
        </div>
        <div
          className="swiper-button-next absolute top-1/2 right-4 transform -translate-y-1/2 z-10 cursor-pointer rounded-full bg-gray-800 hover:bg-gray-700 p-2 transition-colors duration-300"
          style={{ width: "3rem", height: "3rem" }}
        >
          <svg
            className="w-6 h-6 text-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 12H5m14 0-4 4m4-4-4-4"
            />
          </svg>
        </div>
      </div>
    </section>
  );
};

export default TestimonialCarousel;
