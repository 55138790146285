import React, { useEffect, useState } from "react";

const Menu = () => {
  const [bannerData, setBannerData] = useState(null);
  const bannerId = 3;

  useEffect(() => {
    fetch("https://api.dermalicious.id/api/page_banner")
      .then((response) => response.json())
      .then((data) => {
        const MenuBanner = data.find((banner) => banner.id === bannerId);
        setBannerData(MenuBanner);
      })
      .catch((error) => console.error("Error fetching banner data:", error));
  }, []);

  if (!bannerData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <section
        className="relative w-full bg-cover bg-center h-[50vh] md:h-[400px]"
        style={{ backgroundImage: `url(${bannerData.image})` }}
      >
        {/* Aspect Ratio Container for smaller screens */}
        <div className="relative w-full h-full md:hidden flex items-center justify-center bg-black bg-opacity-50">
          <div className="text-xl max-w-3xl p-4 text-center">
            <h2 className="text-white text-4xl font-bold">Menu</h2>
          </div>
        </div>

        {/* Fixed Height Container for larger screens */}
        <div className="hidden md:flex absolute inset-0 items-center">
          <div className="absolute inset-0 flex items-center justify-start pt-52 pl-8 bg-black bg-opacity-50">
            <div className="text-xl max-w-3xl p-10 text-wrap">
              <h2 className="text-white text-5xl font-bold">Menu</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="relative w-full bg-white py-16">
        <div className="container mx-auto px-8 mt-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Image 1 */}
            <div className="relative max-w-xl mx-auto rounded-lg overflow-hidden group">
              <img
                src="/indonesian_food.jpg"
                className="w-full h-auto object-cover rounded-lg transition-opacity duration-300 ease-in-out opacity-70 group-hover:opacity-100"
                alt="Indonesian Food"
              />
              <div className="absolute inset-0 flex items-center justify-center transition-all duration-300 ease-in-out group-hover:items-end">
                <div className="text-white text-center text-2xl font-semibold px-4 py-2 rounded-lg bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-300 ease-in-out">
                  Indonesian Food
                </div>
              </div>
            </div>

            {/* Image 2 */}
            <div className="relative max-w-xl mx-auto rounded-lg overflow-hidden group">
              <img
                src="/asian_food.jpg"
                className="w-full h-auto object-cover rounded-lg transition-opacity duration-300 ease-in-out opacity-70 group-hover:opacity-100"
                alt="Asian Food"
              />
              <div className="absolute inset-0 flex items-center justify-center transition-all duration-300 ease-in-out group-hover:items-end">
                <div className="text-white text-center text-2xl font-semibold px-4 py-2 rounded-lg bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-300 ease-in-out">
                  Asian Food
                </div>
              </div>
            </div>

            {/* Image 3 */}
            <div className="relative max-w-xl mx-auto rounded-lg overflow-hidden group">
              <img
                src="/western_food.jpg"
                className="w-full h-auto object-cover rounded-lg transition-opacity duration-300 ease-in-out opacity-70 group-hover:opacity-100"
                alt="Western Food"
              />
              <div className="absolute inset-0 flex items-center justify-center transition-all duration-300 ease-in-out group-hover:items-end">
                <div className="text-white text-center text-2xl font-semibold px-4 py-2 rounded-lg bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-300 ease-in-out">
                  Western Food
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between px-10 py-8 flex-col md:flex-row">
          <div className="text-left mb-8 md:mb-0">
            <h3 className="text-3xl sm:text-4xl md:text-5xl font-bold">
              Menu Berjalan
            </h3>
          </div>

          <div className="hidden md:block">
            <button
              type="button"
              className="bg-[#4a6a5a] text-white font-medium rounded-lg text-center inline-flex items-center h-16 px-12 hover:bg-[#3b5d4a]"
            >
              Download Disini
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="md:hidden flex justify-center mt-6">
          <button
            type="button"
            className="bg-[#4a6a5a] text-white font-medium rounded-lg text-center inline-flex items-center h-16 px-12 hover:bg-[#3b5d4a]"
          >
            Download Disini
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </button>
        </div>
      </section>
    </div>
  );
};

export default Menu;
