import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const AdminPartnerships = () => {
    const [partnerships, setPartnerships] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formData, setFormData] = useState({ title: '', image: null });
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [editingId, setEditingId] = useState(null);
    
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        fetchPartnerships();
    }, []);

    const fetchPartnerships = async () => {
        try {
            const response = await axios.get('https://103.177.56.115:3000/api/partnerships');
            setPartnerships(Array.isArray(response.data) ? response.data : []);
        } catch {
            setError('Failed to fetch partnerships');
        } finally {
            setLoading(false);
        }
    };

    const openModal = (partnership = null) => {
        if (partnership) {
            setFormData(partnership);
            setImagePreview(partnership.image);
            setIsEditMode(true);
            setEditingId(partnership.id);
        } else {
            setFormData({ title: '', image: null });
            setImagePreview('');
            setIsEditMode(false);
            setEditingId(null);
        }
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setFormData({ title: '', image: null });
        setImagePreview('');
        setIsEditMode(false);
        setEditingId(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            setImagePreview(URL.createObjectURL(file));
        } else {
            setImagePreview('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSubmit = new FormData();
        formDataToSubmit.append('title', formData.title);
        if (imageFile) formDataToSubmit.append('image', imageFile);
        try {
            if (isEditMode) {
                await axios.put(`https://103.177.56.115:3000/api/partnerships/${editingId}`, formDataToSubmit);
            } else {
                await axios.post('https://103.177.56.115:3000/api/partnerships', formDataToSubmit);
            }
            closeModal();
            fetchPartnerships();
        } catch {
            setError('Failed to save partnership');
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this partnership?")) {
            try {
                await axios.delete(`https://103.177.56.115:3000/api/partnerships/${id}`);
                setPartnerships(partnerships.filter(partnership => partnership.id !== id));
            } catch {
                setError('Failed to delete partnership');
            }
        }
    };

    const indexOfLastPartnership = currentPage * itemsPerPage;
    const indexOfFirstPartnership = indexOfLastPartnership - itemsPerPage;
    const currentPartnerships = partnerships.slice(indexOfFirstPartnership, indexOfLastPartnership);
    const totalPages = Math.ceil(partnerships.length / itemsPerPage);

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">Partnerships</h2>
                <button onClick={() => openModal()} className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition">
                    New Partnership
                </button>
            </div>

            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : partnerships.length === 0 ? (
                <p>No partnerships available.</p>
            ) : (
                <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                        <tr className="bg-gray-200 text-gray-600">
                            <th className="py-3 px-4 text-left">No</th>
                            <th className="py-3 px-4 text-left">Title</th>
                            <th className="py-3 px-4 text-left">Image</th>
                            <th className="py-3 px-4 text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPartnerships.map((partnership, index) => (
                            <tr key={partnership.id} className="hover:bg-gray-100">
                                <td className="border px-4 py-2">{index + 1 + indexOfFirstPartnership}</td>
                                <td className="border px-4 py-2">{partnership.title}</td>
                                <td className="border px-4 py-2">
                                    {partnership.image && <img src={partnership.image} alt={partnership.title} className="h-16 object-cover" />}
                                </td>
                                <td className="border px-4 py-2">
                                    <button onClick={() => openModal(partnership)} className="text-blue-500 hover:underline">Edit</button>
                                    <button onClick={() => handleDelete(partnership.id)} className="text-red-500 hover:underline ml-2">Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            <div className="flex justify-between items-center mt-4">
                <button 
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} 
                    disabled={currentPage === 1}
                    className="bg-gray-300 p-2 rounded hover:bg-gray-400 transition"
                >
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button 
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} 
                    disabled={currentPage === totalPages}
                    className="bg-gray-300 p-2 rounded hover:bg-gray-400 transition"
                >
                    Next
                </button>
            </div>

            <Modal 
                isOpen={modalIsOpen} 
                onRequestClose={closeModal} 
                className="modal"
                overlayClassName="overlay"
            >
                <h2 className="text-xl font-bold mb-4">{isEditMode ? 'Edit Partnership' : 'Add Partnership'}</h2>
                <form onSubmit={handleSubmit} className="flex flex-col h-full">
                    <div className="flex-grow overflow-y-auto">
                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium">Title</label>
                                <input 
                                    type="text" 
                                    name="title" 
                                    value={formData.title} 
                                    onChange={handleChange} 
                                    required 
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium">Upload Image</label>
                                <input 
                                    type="file" 
                                    accept="image/*" 
                                    onChange={handleFileChange} 
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                                {imagePreview && <img src={imagePreview} alt="Preview" className="mt-2 w-full h-32 object-cover" />}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end mt-4">
                        <button 
                            type="submit" 
                            className="bg-green-600 text-white p-2 rounded hover:bg-green-700 transition mr-2"
                        >
                            {isEditMode ? 'Update' : 'Create'}
                        </button>
                        <button 
                            type="button" 
                            onClick={closeModal} 
                            className="bg-gray-300 text-gray-700 p-2 rounded hover:bg-gray-400 transition"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default AdminPartnerships;
