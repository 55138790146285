import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";
import axios from "axios";

const PartnershipCarousel = () => {
  const [partnerships, setPartnerships] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    // const fetchPartnerships = async () => {
    //   try {
    //     const response = await axios.get('https://103.177.56.115:3000/api/partnerships');
    //     setPartnerships(response.data);
    //   } catch (err) {
    //     setError('Failed to fetch partnerships');
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    const fetchPartnerships = async () => {
      try {
        const response = await axios.get(
          "https://api.dermalicious.id/api/partnerships"
        ); // Adjusted for proxy
        setPartnerships(response.data);
      } catch (err) {
        setError("Failed to fetch partnerships");
      } finally {
        setLoading(false);
      }
    };

    fetchPartnerships();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <section className="relative w-full bg-white">
      <div className="text-center mb-16">
        <h3 className="text-3xl font-bold md:hidden">Partnership</h3>
        <h3 className="text-5xl font-bold mb-8 text-wrap hidden md:block">
          Partnership
        </h3>
      </div>
      <div className="container mx-auto px-24 md:px-12">
        <Swiper
          spaceBetween={30}
          pagination={{ clickable: true, el: ".swiper-pagination" }}
          autoplay={{
            delay: 1000, // 1 second delay between slides
            disableOnInteraction: false,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          modules={[Pagination, Autoplay]}
          className="swiper-container"
        >
          {partnerships.map((partnership, index) => (
            <SwiperSlide key={index}>
              <div className="flex items-center justify-center pb-8">
                <img
                  src={partnership.image}
                  className="w-80 h-20 object-contain"
                  alt={`partnership ${index}`}
                />
              </div>
            </SwiperSlide>
          ))}
          {/* Custom pagination container */}
          <div className="swiper-pagination"></div>
        </Swiper>
      </div>
    </section>
  );
};

export default PartnershipCarousel;
