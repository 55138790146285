import React, { useEffect, useState } from "react";

const TentangKami = () => {
  const [bannerData, setBannerData] = useState(null);
  const bannerId = 1;

  useEffect(() => {
    fetch("https://api.dermalicious.id/api/page_banner")
      .then((response) => response.json())
      .then((data) => {
        const tentangKamiBanner = data.find((banner) => banner.id === bannerId);
        setBannerData(tentangKamiBanner);
      })
      .catch((error) => console.error("Error fetching banner data:", error));
  }, []);

  if (!bannerData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <section
        className="relative w-full bg-cover bg-center h-[50vh] md:h-[400px]"
        style={{ backgroundImage: `url(${bannerData.image})` }}
      >
        {/* Aspect Ratio Container for smaller screens */}
        <div className="relative w-full h-full md:hidden flex items-center justify-center bg-black bg-opacity-50">
          <div className="text-xl max-w-3xl p-4 text-center">
            <h2 className="text-white text-4xl font-bold">Tentang Kami</h2>
          </div>
        </div>

        {/* Fixed Height Container for larger screens */}
        <div className="hidden md:flex absolute inset-0 items-center">
          <div className="absolute inset-0 flex items-center justify-start pt-52 pl-8 bg-black bg-opacity-50">
            <div className="text-xl max-w-3xl p-10 text-wrap">
              <h2 className="text-white text-5xl font-bold">Tentang Kami</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white py-24 px-6">
        <div className="mx-2 md:mx-12 mx-auto text-left select-none">
          <h4 className="text-left text-3xl font-bold mb-4">
            PT. Bumi Estetika Bahagia Gemilang
          </h4>
          <p className="text-left text-lg text-gray-700 mb-4">
            adalah usaha yang bergerak sebagai penyedia jasa makanan sehat
            dengan menu makan yang bervariatif, lezat, dan anti hambar.
            Komposisi terukur oleh Clinical Nutrition Doctor, dan diolah
            langsung oleh gourmet chef yang bersertifikat. Kami juga memiliki
            Sertifikasi HACCP dan Sertifikasi Pelatihan Keamanan Siap Saji
            sebagai pengawasan jaminan mutu dan standar keamanan produk yang
            menjadi jaminan makanan kami aman untuk dikonsumsi.
          </p>
          <p className="text-left text-lg text-gray-700">
            PT. Bumi Estetika Bahagia Gemilang menyediakan beberapa program
            catering diantaranya : Slimming, Healthy, Personalized, Gain Muscle
            dan Mommy. Demi menyempurnakan program penurunan berat badan serta
            kebutuhan untuk tubuh, PT Bumi Estetika Bahagia Gemilang juga
            menyediakan meal replacement dan fiber drink.
          </p>
        </div>
      </section>

      <section className="bg-[#E5F5EC] py-12 px-6">
        <div className="mx-2 md:mx-12 mx-auto text-left select-none">
          <h4 className="underline underline-offset-8 decoration-3 text-left text-3xl font-bold text-wrap mb-4">
            Visi
          </h4>
          <p className="text-left text-lg text-gray-700 text-wrap mb-4">
            Menjadikan PT. Bumi Estetika Bahagia Gemilang diet & healthy
            catering terbesar di Indonesia.
          </p>
          <h4 className="underline underline-offset-8 decoration-3 text-left text-3xl font-bold text-wrap mb-4">
            Misi
          </h4>
          <ul className="list-disc pl-6 md:pr-44 sm:pr-6">
            <li>
              Mengubah mindset masyarakat bahwa diet itu tidak harus lapar,
              lemas dan makanan hambar
            </li>
            <li>
              Menciptakan makanan yang terukur komposisinya enak dan lezat
            </li>
            <li>Menurunkan BB menuju berat badan ideal</li>
            <li>
              Mengedukasi masyarakat mengenai pola hidup sehat dan nutrisi yang
              baik untuk tubuh
            </li>
            <li>
              Membantu menurunkan resiko non-communicable disease penyakit
              kronik (jerawat, jantung, kolesterol, asam urat, hipertensi,
              trigliserida, kanker, diabetes, stroke)
            </li>
            <li>Menjadikan tubuh lebih sehat dan bugar</li>
          </ul>
        </div>
      </section>

      <section className="relative w-full bg-white py-24 px-6 select-none">
        {/* <div className="text-center mb-16">
          <h3 className="text-5xl font-bold mb-8 text-wrap">Kenapa Memilih Kami ?</h3>
        </div> */}
        <div className="text-center mb-16 md:hidden">
          <h3 className="text-2xl font-bold mb-8 text-wrap">
            Kenapa Memilih Kami ?
          </h3>
        </div>
        <div className="hidden md:flex justify-center mb-16">
          <h3 className="text-5xl font-bold mb-8 text-wrap">
            Kenapa Memilih Kami ?
          </h3>
        </div>
        <div className="container mx-auto px-8">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
            {/* Item 1 */}
            <div className="max-w-xl mx-auto rounded-lg overflow-hidden bg-[#E5F5EC] shadow-lg">
              <div className="flex flex-col sm:flex-row items-center p-4">
                <img
                  src="/tentangkami/bahan.jpg" // Replace with your image path
                  alt="Bahan Makanan"
                  className="w-full h-auto object-cover rounded-lg"
                />
              </div>
            </div>
            {/* Item 2 */}
            <div className="max-w-xl mx-auto rounded-lg overflow-hidden bg-[#E5F5EC] shadow-lg">
              <div className="flex flex-col sm:flex-row items-center p-4">
                <img
                  src="/tentangkami/rasa.jpg" // Replace with your image path
                  alt="Komposisi Makanan"
                  className="w-full h-auto object-cover rounded-lg"
                />
              </div>
            </div>
            {/* Item 3 */}
            <div className="max-w-xl mx-auto rounded-lg overflow-hidden bg-[#E5F5EC] shadow-lg">
              <div className="flex flex-col sm:flex-row items-center p-4">
                <img
                  src="/tentangkami/ultraprocess.jpg" // Replace with your image path
                  alt="Tanpa Ultra Processed Food"
                  className="w-full h-auto object-cover rounded-lg"
                />
              </div>
            </div>
            {/* Item 4 */}
            <div className="max-w-xl mx-auto rounded-lg overflow-hidden bg-[#E5F5EC] shadow-lg">
              <div className="flex flex-col sm:flex-row items-center p-4">
                <img
                  src="/tentangkami/komposisi.jpg" // Replace with your image path
                  alt="Komposisi oleh Dokter"
                  className="w-full h-auto object-cover rounded-lg"
                />
              </div>
            </div>
            {/* Item 5 */}
            <div className="max-w-xl mx-auto rounded-lg overflow-hidden bg-[#E5F5EC] shadow-lg">
              <div className="flex flex-col sm:flex-row items-center p-4">
                <img
                  src="/tentangkami/packaging.jpg" // Replace with your image path
                  alt="Packaging Ramah Lingkungan"
                  className="w-full h-auto object-cover rounded-lg"
                />
              </div>
            </div>
            {/* Item 6 */}
            <div className="max-w-xl mx-auto rounded-lg overflow-hidden bg-[#E5F5EC] shadow-lg">
              <div className="flex flex-col sm:flex-row items-center p-4">
                <img
                  src="/tentangkami/hidroponik.jpg" // Replace with your image path
                  alt="Sayuran Hidroponik"
                  className="w-full h-auto object-cover rounded-lg"
                />
              </div>
            </div>
            {/* Item 7 */}
            <div className="max-w-xl mx-auto rounded-lg overflow-hidden bg-[#E5F5EC] shadow-lg">
              <div className="flex flex-col sm:flex-row items-center p-4">
                <img
                  src="/tentangkami/tanpamsg.jpg" // Replace with your image path
                  alt="Bumbu Dapur"
                  className="w-full h-auto object-cover rounded-lg"
                />
              </div>
            </div>
            {/* Item 8 */}
            <div className="max-w-xl mx-auto rounded-lg overflow-hidden bg-[#E5F5EC] shadow-lg">
              <div className="flex flex-col sm:flex-row items-center p-4">
                <img
                  src="/tentangkami/minyak.jpg" // Replace with your image path
                  alt="Minyak Sehat"
                  className="w-full h-auto object-cover rounded-lg"
                />
              </div>
            </div>
            {/* Item 9 */}
            <div className="max-w-xl mx-auto rounded-lg overflow-hidden bg-[#E5F5EC] shadow-lg">
              <div className="flex flex-col sm:flex-row items-center p-4">
                <img
                  src="/tentangkami/ongkir.jpg" // Replace with your image path
                  alt="Biaya Pengiriman"
                  className="w-full h-auto object-cover rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TentangKami;
