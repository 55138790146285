import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { navLinks } from './NavLinks';

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <header className="bg-white text-black shadow-md relative">
        {/* Logo Section */}
        <div className="flex justify-center items-center p-4">
          <Link to="/">
            <img 
              src="/logo_dermalicious.png" 
              alt="Logo Dermalicious" 
              className="h-[100px] w-[200px] object-contain" 
            />
          </Link>
        </div>

        {/* Hamburger / Close Icon */}
        <button 
          className="block md:hidden text-black focus:outline-none absolute top-4 right-4 z-20"
          onClick={toggleMenu}
        >
          {isOpen ? (
            <svg 
              className="w-6 h-6" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M6 18L18 6M6 6l12 12" 
              />
            </svg>
          ) : (
            <svg 
              className="w-6 h-6" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M4 6h16M4 12h16m-7 6h7" 
              />
            </svg>
          )}
        </button>
      </header>

      {/* Line Shadow between logo and navbar */}
      <div className="w-full h-[1px] bg-gray-150 shadow-xl mb-12"></div>

      {/* Mobile Menu */}
      <nav 
        className={`fixed inset-0 bg-white top-[60px] md:hidden ${isOpen ? 'flex' : 'hidden'} flex-col items-center justify-center space-y-4 transition-transform transform ${isOpen ? 'translate-y-0' : '-translate-y-full'} ease-in-out duration-300 z-10`}
      >
        {navLinks.map((link) => (
          <Link
            key={link.to}
            to={link.to}
            className={`font-semibold text-lg px-4 py-2 rounded-md text-center w-full ${location.pathname === link.to ? 'bg-[#4a6a5a] text-white font-bold' : 'text-black hover:bg-gray-100 hover:text-gray-500'}`}
            onClick={() => setIsOpen(false)} // Close menu on item click
          >
            {link.label}
          </Link>
        ))}
      </nav>

      {/* Desktop Navbar */}
      <nav className="hidden md:flex md:justify-center md:items-center md:space-x-6 mb-12">
        {navLinks.map((link) => (
          <Link
            key={link.to}
            to={link.to}
            className={`font-semibold ${location.pathname === link.to ? 'text-[#4a6a5a]' : 'hover:text-gray-500'}`}
          >
            {link.label}
          </Link>
        ))}
      </nav>
    </div>
  );
};
