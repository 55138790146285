import React, { useEffect, useState } from "react";
import axios from "axios";

const Kontak = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [eazyMeals, setEazyMeals] = useState([]);

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        await axios.get("https://api.dermalicious.id/api/links");
        const mealsResponse = await axios.get(
          "api.dermalicious.id/api/eazy_meals"
        );

        setEazyMeals(
          Array.isArray(mealsResponse.data) ? mealsResponse.data : []
        );
      } catch {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchLinks();
  }, []);

  return (
    <div>
      {/* First Section */}
      <section className="relative w-full bg-[#E5F5EC] select-none">
        <div className="container flex flex-col md:flex-row items-center justify-between mx-auto">
          {/* Left Side: Image */}
          <div className="w-full md:w-1/2 h-[550px] flex-shrink-0">
            <img
              src="/kontak_1.jpg"
              alt="Additional Content"
              className="w-full h-full object-cover"
            />
          </div>

          {/* Right Side: Text Content */}
          <div className="w-full md:w-1/2 p-4 sm:p-4 md:px-0 lg:p-12 text-left space-y-6 md:space-y-8">
            <h3 className="text-2xl sm:text-3xl md:text-4xl font-bold my-6">
              Isi form dibawah ini dan tim kami akan segera menghubungi kamu :
            </h3>
            <div className="flex flex-col space-y-2 mb-8">
              <h4 className="text-xl sm:text-2xl md:text-3xl font-semibold">
                Premium Catering
              </h4>
              <a
                href="https://forms.gle/NWdQ6tmKBzA7s1my6"
                target="_blank"
                rel="noopener noreferrer"
                className="block"
              >
                <button className="bg-[#4a6a5a] text-white w-40 font-medium h-12 rounded-lg hover:bg-[#3b5d4a]">
                  Daftar Disini
                </button>
              </a>
            </div>
            <div className="flex flex-col space-y-2">
              <h4 className="text-xl sm:text-2xl md:text-3xl font-semibold">
                Personalized Catering
              </h4>
              <a
                href="https://forms.gle/1cT8Po1vtWUutAfH8"
                target="_blank"
                rel="noopener noreferrer"
                className="block"
              >
                <button className="bg-[#4a6a5a] text-white w-40 font-medium h-12 rounded-lg hover:bg-[#3b5d4a]">
                  Daftar Disini
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* Second Section */}
      <section className="relative w-full bg-[#4a6a5a] select-none">
        <div className="container flex flex-col md:flex-row-reverse items-center justify-between mx-auto">
          {/* Right Side: Image */}
          <div className="w-full md:w-1/2 h-[550px] flex-shrink-0">
            <img
              src="/kontak_1.jpg"
              alt="Additional Content"
              className="w-full h-full object-cover"
            />
          </div>

          {/* Left Side: Text Content with Dividers */}
          <div className="w-full md:w-1/2 p-4 text-left space-y-6 md:space-y-12">
            <div className="flex flex-col items-start">
              <h4 className="text-xl sm:text-2xl md:text-3xl text-white font-semibold mb-4">
                <a
                  href="mailto:adm.dermalicious@gmail.com"
                  className="hover:underline"
                >
                  Email: adm.dermalicious@gmail.com
                </a>
              </h4>
              <hr className="border-gray-300 w-full mb-4" />
              <h4 className="text-xl sm:text-2xl md:text-3xl text-white font-semibold mb-4">
                <a
                  href="https://wa.me/6281399022776"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  Whatsapp: +62 813-9902-2776
                </a>
              </h4>
              <hr className="border-gray-300 w-full mb-4" />
              <h4 className="text-xl sm:text-2xl md:text-3xl text-white font-semibold mb-4">
                <a
                  href="https://www.instagram.com/dermalicious.id"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  Instagram: Dermalicious.id
                </a>
              </h4>
            </div>
          </div>
        </div>
      </section>
      {/* Third Section */}
      <section className="relative w-full bg-[#E5F5EC] select-none">
        <div className="container flex flex-col pb-4 md:flex-row items-stretch mx-auto">
          {/* Left Side: Image */}
          <div className="w-full md:w-1/2 flex-shrink-0">
            <img
              src="/eazymeal.jpg"
              alt="Eazy Meal"
              className="w-full h-full object-cover"
            />
          </div>

          {/* Right Side: Text Content and Image List */}
          <div className="w-full md:w-1/2 p-4 sm:p-4 md:px-0 lg:p-12 text-left space-y-6 md:space-y-8">
            <h3 className="text-2xl sm:text-3xl md:text-4xl font-bold my-6">
              Dermalicious juga dapat dipesan dalam kemasan Eazy Meals :
            </h3>

            {/* Eazy Meals Image List */}
            <div className="flex flex-col space-y-4 mb-8">
              {loading ? (
                <p>Loading...</p>
              ) : error ? (
                <p className="text-red-500">{error}</p>
              ) : (
                eazyMeals.map((meal, index) => (
                  <img
                    key={index}
                    src={meal.image}
                    alt={`Eazy Meal ${index + 1}`}
                    className="w-80 h-20 object-contain"
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </section>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : null}{" "}
      {/* No footer here */}
    </div>
  );
};

export default Kontak;
