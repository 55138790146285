import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const AdminTestimonials = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formData, setFormData] = useState({ name: '', role: '', image: null, rating: '', text: '' });
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [editingId, setEditingId] = useState(null);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;


    useEffect(() => {
        fetchTestimonials();
    }, []);

    const fetchTestimonials = async () => {
        try {
            const response = await axios.get('https://103.177.56.115:3000/api/testimonials');
            setTestimonials(Array.isArray(response.data) ? response.data : []);
        } catch {
            setError('Failed to fetch testimonials');
        } finally {
            setLoading(false);
        }
    };

    const openModal = (testimonial = null) => {
        if (testimonial) {
            setFormData(testimonial);
            setImagePreview(testimonial.image);
            setIsEditMode(true);
            setEditingId(testimonial.id);
        } else {
            setFormData({ name: '', role: '', image: null, rating: '', text: '' });
            setImagePreview('');
            setIsEditMode(false); // Ensure this is false for new testimonials
            setEditingId(null);
        }
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        // Optionally reset states if needed
    };    

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        // Check if the input is for rating and limit to single digit 1-5
        if (name === "rating") {
            if (/^[1-5]?$/.test(value)) {
                setFormData({ ...formData, [name]: value });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            setImagePreview(URL.createObjectURL(file));
        } else {
            setImagePreview('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (formData.rating < 1 || formData.rating > 5 || formData.rating.length > 1) {
            alert("Rating must be a single digit between 1 and 5");
            return; // Prevent submission if invalid
        }
    
        const formDataToSubmit = new FormData();
        formDataToSubmit.append('name', formData.name);
        formDataToSubmit.append('role', formData.role);
        if (imageFile) formDataToSubmit.append('image', imageFile);
        formDataToSubmit.append('rating', formData.rating);
        formDataToSubmit.append('text', formData.text);
    
        try {
            if (isEditMode) {
                await axios.put(`https://103.177.56.115:3000/api/testimonials/${editingId}`, formDataToSubmit);
            } else {
                await axios.post('https://103.177.56.115:3000/api/testimonials', formDataToSubmit);
            }
            closeModal();
            fetchTestimonials();
        } catch {
            setError('Failed to save testimonial');
        }
    };    

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this testimonial?")) {
            try {
                await axios.delete(`https://103.177.56.115:3000/api/testimonials/${id}`);
                setTestimonials(testimonials.filter(testimonial => testimonial.id !== id));
            } catch {
                setError('Failed to delete testimonial');
            }
        }
    };

    // Pagination logic
    const indexOfLastTestimonial = currentPage * itemsPerPage;
    const indexOfFirstTestimonial = indexOfLastTestimonial - itemsPerPage;
    const currentTestimonials = testimonials.slice(indexOfFirstTestimonial, indexOfLastTestimonial);
    const totalPages = Math.ceil(testimonials.length / itemsPerPage);

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">Testimonials</h2>
                <button onClick={() => openModal()} className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition">
                    New Testimonial
                </button>
            </div>

            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : testimonials.length === 0 ? (
                <p>No testimonials available.</p>
            ) : (
                <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                        <tr className="bg-gray-200 text-gray-600">
                            <th className="py-3 px-4 text-left">No</th>
                            <th className="py-3 px-4 text-left">Name</th>
                            <th className="py-3 px-4 text-left">Role</th>
                            <th className="py-3 px-4 text-left">Image</th>
                            <th className="py-3 px-4 text-left">Rating</th>
                            <th className="py-3 px-4 text-left">Text</th>
                            <th className="py-3 px-4 text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
    {currentTestimonials.map((testimonial, index) => (
        <tr key={testimonial.id} className="hover:bg-gray-100">
            <td className="border px-4 py-2">{index + 1 + indexOfFirstTestimonial}</td>
            <td className="border px-4 py-2">{testimonial.name}</td>
            <td className="border px-4 py-2">{testimonial.role}</td>
            <td className="border px-4 py-2">
                {testimonial.image && <img src={testimonial.image} alt={testimonial.name} className="w-16 h-16 object-cover" />}
            </td>
            <td className="border px-4 py-2">{testimonial.rating}</td>
            <td className="border px-4 py-2">{testimonial.text}</td>
            <td className="border px-4 py-2">
                <button onClick={() => openModal(testimonial)} className="text-blue-500 hover:underline">Edit</button>
                <button onClick={() => handleDelete(testimonial.id)} className="text-red-500 hover:underline ml-2">Delete</button>
            </td>
        </tr>
    ))}
</tbody>

                </table>
            )}
            <div className="flex justify-between items-center mt-4">
    <button 
        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} 
        disabled={currentPage === 1}
        className="bg-gray-300 p-2 rounded hover:bg-gray-400 transition"
    >
        Previous
    </button>
    <span>Page {currentPage} of {totalPages}</span>
    <button 
        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} 
        disabled={currentPage === totalPages}
        className="bg-gray-300 p-2 rounded hover:bg-gray-400 transition"
    >
        Next
    </button>
</div>

            <Modal 
    isOpen={modalIsOpen} 
    onRequestClose={closeModal} 
    className="modal"
    overlayClassName="overlay"
>
    <h2 className="text-xl font-bold mb-4">{isEditMode ? 'Edit Testimonial' : 'Add Testimonial'}</h2>
    <form onSubmit={handleSubmit} className="flex flex-col h-full">
        <div className="flex-grow overflow-y-auto">
            <div className="space-y-4">
                {/* Input fields remain the same */}
                <div>
                    <label className="block text-sm font-medium">Name</label>
                    <input 
                        type="text" 
                        name="name" 
                        value={formData.name} 
                        onChange={handleChange} 
                        required 
                        className="border border-gray-300 rounded p-2 w-full"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium">Role</label>
                    <input 
                        type="text" 
                        name="role" 
                        value={formData.role} 
                        onChange={handleChange} 
                        required 
                        className="border border-gray-300 rounded p-2 w-full"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium">Upload Image (optional)</label>
                    <input 
                        type="file" 
                        accept="image/*" 
                        onChange={handleFileChange} 
                        className="border border-gray-300 rounded p-2 w-full"
                    />
                    {imagePreview && <img src={imagePreview} alt="Preview" className="mt-2 w-32 h-32 object-cover" />}
                </div>
                <div>
                    <label className="block text-sm font-medium">Rating (1 to 5)</label>
                    <input 
                        type="number" 
                        name="rating" 
                        value={formData.rating} 
                        onChange={handleChange} 
                        min="1" 
                        max="5"
                        required 
                        className="border border-gray-300 rounded p-2 w-full"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium">Testimonial</label>
                    <textarea 
                        name="text" 
                        value={formData.text} 
                        onChange={handleChange} 
                        required 
                        className="border border-gray-300 rounded p-2 w-full"
                    />
                </div>
            </div>
        </div>
        <div className="flex justify-end mt-4">
            <button 
                type="submit" 
                className="bg-green-600 text-white p-2 rounded hover:bg-green-700 transition mr-2"
            >
                {isEditMode ? 'Update' : 'Create'}
            </button>
            <button 
                type="button" 
                onClick={closeModal} 
                className="bg-gray-300 text-gray-700 p-2 rounded hover:bg-gray-400 transition"
            >
                Cancel
            </button>
        </div>
    </form>
</Modal>

        </div>
    );
};

export default AdminTestimonials;
