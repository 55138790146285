import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const AdminLinks = () => {
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formData, setFormData] = useState({ id: '', facebook: '', instagram: '', youtube: '', tiktok: '', whatsapp: '', email: '' });
    const [isEditMode, setIsEditMode] = useState(false);
    const [editingId, setEditingId] = useState(null);

    useEffect(() => {
        fetchLinks();
    }, []);

    const fetchLinks = async () => {
        try {
            const response = await axios.get('https://103.177.56.115:3000/api/links');
            setLinks(Array.isArray(response.data) ? response.data : []);
        } catch {
            setError('Failed to fetch links');
        } finally {
            setLoading(false);
        }
    };

    const openModal = (link = null) => {
        if (link) {
            setFormData(link);
            setIsEditMode(true);
            setEditingId(link.id);
        } else {
            setFormData({ id: '', facebook: '', instagram: '', youtube: '', tiktok: '', whatsapp: '', email: '' });
            setIsEditMode(false);
            setEditingId(null);
        }
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEditMode) {
                await axios.put(`https://103.177.56.115:3000/api/links/${editingId}`, formData);
            } else {
                await axios.post('https://103.177.56.115:3000/api/links', formData);
            }
            closeModal();
            fetchLinks();
        } catch {
            setError('Failed to save link');
        }
    };

    // const handleDelete = async (id) => {
    //     if (window.confirm("Are you sure you want to delete this link?")) {
    //         try {
    //             await axios.delete(`http://localhost:5000/api/links/${id}`);
    //             setLinks(links.filter(link => link.id !== id));
    //         } catch {
    //             setError('Failed to delete link');
    //         }
    //     }
    // };

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">Links</h2>
                {/* <button onClick={() => openModal()} className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition">
                    New Link
                </button> */}
            </div>

            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : links.length === 0 ? (
                <p>No links available.</p>
            ) : (
                <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                        <tr className="bg-gray-200 text-gray-600">
                            <th className="py-3 px-4 text-left">No</th>
                            <th className="py-3 px-4 text-left">Facebook</th>
                            <th className="py-3 px-4 text-left">Instagram</th>
                            <th className="py-3 px-4 text-left">Youtube</th>
                            <th className="py-3 px-4 text-left">Tiktok</th>
                            <th className="py-3 px-4 text-left">WhatsApp</th>
                            <th className="py-3 px-4 text-left">Email</th>
                            <th className="py-3 px-4 text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {links.map((link, index) => (
                            <tr key={link.id} className="hover:bg-gray-100">
                                <td className="border px-4 py-2">{index + 1}</td>
                                <td className="border px-4 py-2">{link.facebook}</td>
                                <td className="border px-4 py-2">{link.instagram}</td>
                                <td className="border px-4 py-2">{link.youtube}</td>
                                <td className="border px-4 py-2">{link.tiktok}</td>
                                <td className="border px-4 py-2">{link.whatsapp}</td>
                                <td className="border px-4 py-2">{link.email}</td>
                                <td className="border px-4 py-2">
                                    <button onClick={() => openModal(link)} className="text-blue-500 hover:underline">Edit</button>
                                    {/* <button onClick={() => handleDelete(link.id)} className="text-red-500 hover:underline ml-2">Delete</button> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            
            <Modal 
                isOpen={modalIsOpen} 
                onRequestClose={closeModal} 
                className="modal"
                overlayClassName="overlay"
            >
                <h2 className="text-xl font-bold mb-4">{isEditMode ? 'Edit Link' : 'Add Link'}</h2>
                <form onSubmit={handleSubmit} className="flex flex-col">
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium">Facebook</label>
                            <input 
                                type="text" 
                                name="facebook" 
                                value={formData.facebook} 
                                onChange={handleChange} 
                                required 
                                className="border border-gray-300 rounded p-2 w-full"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium">Instagram</label>
                            <input 
                                type="text" 
                                name="instagram" 
                                value={formData.instagram} 
                                onChange={handleChange} 
                                required 
                                className="border border-gray-300 rounded p-2 w-full"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium">Youtube</label>
                            <input 
                                type="text" 
                                name="youtube" 
                                value={formData.youtube} 
                                onChange={handleChange} 
                                required 
                                className="border border-gray-300 rounded p-2 w-full"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium">Tiktok</label>
                            <input 
                                type="text" 
                                name="tiktok" 
                                value={formData.tiktok} 
                                onChange={handleChange} 
                                required 
                                className="border border-gray-300 rounded p-2 w-full"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium">WhatsApp</label>
                            <input 
                                type="text" 
                                name="whatsapp" 
                                value={formData.whatsapp} 
                                onChange={handleChange} 
                                required 
                                className="border border-gray-300 rounded p-2 w-full"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium">Email</label>
                            <input 
                                type="email" 
                                name="email" 
                                value={formData.email} 
                                onChange={handleChange} 
                                required 
                                className="border border-gray-300 rounded p-2 w-full"
                            />
                        </div>
                    </div>
                    <div className="flex justify-end mt-4">
                        <button 
                            type="submit" 
                            className="bg-green-600 text-white p-2 rounded hover:bg-green-700 transition mr-2"
                        >
                            {isEditMode ? 'Update' : 'Create'}
                        </button>
                        <button 
                            type="button" 
                            onClick={closeModal} 
                            className="bg-gray-300 text-gray-700 p-2 rounded hover:bg-gray-400 transition"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default AdminLinks;
